<template>
  <LeadCapture
    :branch="branch"
    :branding="branding"
    :content="content"
    :url="url"
  >
    <div class="flex flex-col justify-center text-center space-y-8">
      <div class="flex flex-col items-center">
        <div
          v-if="branch.branding.logo"
          class="max-w-[12rem] max-h-24 flex justify-center my-4 mb-12 lg:mb-16"
        >
          <img
            class="h-auto object-contain"
            :src="branch.branding.logo.url"
            :alt="branch.extendedBranchName"
          >
        </div>

        <div class="col-span-1 flex flex-col justify-center w-full items-center py-2 overflow-x-hidden">
          <h1 class="text-lead-capture-title font-lead-capture-title text-4xl leading-10 font-extrabold mb-4">
            {{ content.title }}
          </h1>
          <p class="text-lead-capture-description font-lead-capture-description text-sm sm:text-base text-gray-700 leading-6 font-normal">
            {{ content.description }}
          </p>
        </div>
      </div>

      <AddressSearchWithButton
        :initial-query="postcode"
        :selected-property="selectedProperty"
        @search-completed="scrollPage"
        @property-selected="setProperty"
        @confirm-property-selection="submit"
        @clear-selected-property="clearSelectedProperty"
      />

      <span
        v-if="content.showSampleReportLink && content.sampleReportUrl"
        class="font-report-primary block text-sm leading-5 font-medium pt-4 text-center"
      >
        View a sample report?
        <a
          class="font-report-primary"
          :href="content.sampleReportUrl"
          target="_blank"
        >
          Click here
        </a>
      </span>
    </div>
  </LeadCapture>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import AddressSearchWithButton from '@/Components/AddressSearchWithButton.vue';
import LeadCapture from '@/Layouts/LeadCapture.vue';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps<{
  branch: App.Data.BranchData,
  branding: App.Data.BrandData,
  content: App.Data.LeadCaptureHomepageData,
  endpoint: string,
  url: string,
  postcode: string | null,
}>();

const selectedProperty = ref(null);

const form = useForm<{ address: string | null, source: string | null }>({
  address: null,
  source: null,
});

const setProperty = (property: any) => {
  form.address = property.barcode;
  selectedProperty.value = property;
};

const clearSelectedProperty = () => {
  selectedProperty.value = null;
};

const scrollPage = () => {
  setTimeout(() => window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  }), 200);
};

const submit = () => form.post(props.endpoint);

onMounted(() => {
  if (!props.branch.branding.googleTagManager) {
    return;
  }

  const sourceUuid = uuidv4();
  form.source = sourceUuid;
  const sourceUrl = new URL(window.location.toString()).toString();
  localStorage.setItem(sourceUuid, sourceUrl);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    source: sourceUrl,
  });
});
</script>

<template>
  <div
    v-click-outside="handleClickOutside"
    class="relative w-full h-full"
  >
    <AisInstantSearch
      v-if="searchClient"
      :search-client="searchClient"
      index-name="addresses:building_number:asc"
      class="w-full h-full"
    >
      <div class="flex gap-2 justify-center">
        <AisSearchBox class="flex flex-row gap-2 relative mt-1 rounded-md w-full xl:w-3/4">
          <template #default="{ refine }">
            <div
              v-if="!selectedProperty"
              class="relative w-full flex space-x-4"
            >
              <InsightsInput
                v-model="searchQuery"
                name="addressSearch"
                focus-input
                autofocus
                placeholder="Enter an address to search"
                class="w-full placeholder-blue-gray-400 rounded-md border-blue-gray-300 sm:text-sm focus:border-blue-300 focus:ring-opacity-50 focus:ring-2 focus:ring-blue-400 px-5 py-3"
                @keyup.enter="refineSearch(refine, searchQuery)"
              />

              <button
                class="inline-flex items-center rounded-lg space-x-sm py-sm px-base text-sm h-full bg-lead-capture-button text-lead-capture-button-text font-lead-capture-button"
                @click="refineSearch(refine, searchQuery)"
              >
                <AisStateResults>
                  <template #default="{ status }">
                    <span v-if="!['loading', 'stalled'].includes(status)">Search</span>
                    <InsightsSpinner v-show="status === 'loading' || status === 'stalled'" />
                  </template>
                </AisStateResults>
              </button>
            </div>

            <div
              v-else
              class="flex flex-col items-start w-full"
            >
              <div class="mb-1 flex space-x-1 items-center relative w-full">
                <InsightsInput
                  :model-value="selectedProperty.single_line_address"
                  name="address"
                  autocomplete="false"
                  disabled
                  autofocus
                  class="w-full placeholder-blue-gray-400 rounded-md border-blue-gray-300 sm:text-sm focus:border-blue-300 focus:ring-opacity-50 focus:ring-2 focus:ring-blue-400 px-5 py-3"
                />

                <button
                  type="submit"
                  class="inline-flex items-center rounded-lg space-x-sm py-sm px-base text-sm h-full bg-lead-capture-button text-lead-capture-button-text font-lead-capture-button"
                  @click="$emit('confirmPropertySelection')"
                >
                  Continue
                </button>
              </div>

              <div class="px-5">
                <button
                  class="cursor-pointer text-xs text-gray-500 hover:text-gray-900"
                  @click="clearSearch"
                >
                  Search for a different property?
                </button>
              </div>
            </div>
          </template>
        </AisSearchBox>
      </div>
      <div
        v-if="displayResults"
        class="flex flex-col items-center"
      >
        <AisInfiniteHits
          v-bind="$attrs"
          class="py-1 mt-1 rounded-md w-full xl:w-3/4"
        >
          <template #default="{ items, refineNext, isLastPage }">
            <ul
              v-if="items.length"
              class="overflow-auto pb-1 w-full max-h-60 text-base bg-white md:rounded-md divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none sm:text-sm"
              tabindex="-1"
              role="listbox"
            >
              <li class="py-2 pl-3 text-sm font-semibold text-gray-500 bg-gray-100 text-left">
                Select Address
              </li>
              <li
                v-for="item in items"
                :key="item.id"
                role="option"
              >
                <button
                  class="w-full relative py-2 pr-9 pl-3 text-sm font-medium text-gray-500 cursor-pointer select-none hover:text-gray-800 focus:bg-gray-100 focus:outline-none hover:bg-gray-100 text-left"
                  @click="selectProperty(item)"
                >
                  <span class="block font-normal truncate">
                    <AisHighlight
                      :hit="item"
                      attribute="single_line_address"
                    />
                  </span>
                </button>
              </li>
              <li
                v-observe-visibility="{callback: (isVisible: boolean) => visibilityChanged(isVisible, refineNext, isLastPage), throttle: 300 }"
                class="list-none"
              />
            </ul>
          </template>
        </AisInfiniteHits>
      </div>
    </AisInstantSearch>

    <div
      v-if="!searchClient && !ErrorService.getCustomError('retry')"
      class="flex justify-center items-center"
    >
      <InsightsSpinner />
    </div>

    <InsightsAlert
      v-if="ErrorService.getCustomError('retry')"
      type="error"
    >
      Address search currently unavailable. Please refresh the page.
    </InsightsAlert>
  </div>
</template>

<script setup lang="ts">
import { instantMeiliSearch, type InstantMeiliSearchInstance } from '@meilisearch/instant-meilisearch';
import { onMounted, ref } from 'vue';
import { retry } from '@/Support/retry';
import { usePage } from '@inertiajs/vue3';
import {
  AisInstantSearch, AisSearchBox, AisStateResults, AisInfiniteHits, AisHighlight,
  // @ts-ignore
} from 'vue-instantsearch/vue3/es';
import ErrorService from '@/Services/ErrorService';
import InsightsInput from './Forms/InsightsInput.vue';
import InsightsSpinner from './InsightsSpinner.vue';
import InsightsAlert from './InsightsAlert.vue';

const props = defineProps<{
  selectedProperty: any | null,
  initialQuery: string | null,
}>();

const emit = defineEmits([
  'searchCompleted',
  'propertySelected',
  'confirmPropertySelection',
  'clearSelectedProperty',
]);

const page = usePage();
const searchQuery = ref(props.initialQuery ?? '');
const displayResults = ref(false);
const searchClient = ref<InstantMeiliSearchInstance | null>(null);

const createSearchClient = () => instantMeiliSearch(
  page.props.config.kraken.search_url,
  page.props.config.kraken.api_key,
);

const selectProperty = (property: any) => {
  emit('propertySelected', property);

  displayResults.value = false;
};

const refineSearch = (refine: Function, value: string) => {
  searchQuery.value = value;

  refine(value);

  displayResults.value = true;

  emit('searchCompleted');
};

const clearSearch = () => {
  emit('clearSelectedProperty');

  searchQuery.value = '';
  displayResults.value = false;
};

const handleClickOutside = () => {
  displayResults.value = false;
};

const visibilityChanged = (isVisible: boolean, refineNext: any, isLastPage: boolean) => {
  if (isVisible && !isLastPage) {
    refineNext();
  }
};

onMounted(async () => {
  const client = await retry(() => createSearchClient(), 3);

  searchClient.value = client;
});
</script>
